<template>
    <footer class="footer has-cards">
        <div class="container">
            <div class="row">                
                <div class="col">
                    <ul class="nav nav-footer justify-content-end">
                        <!--<li class="nav-item">
                    <a class="nav-link" target="_blank" rel="noopener">
                        About
                        Us
                    </a>
                </li>-->
                        <li class="nav-item">
                            <a target="_blank" rel="noopener" href="https://www.facebook.com/immobldotcom"
                               class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"
                               data-original-title="Like us">
                                <i class="fa fa-facebook-square"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col">
                    <div class="copyright" style="text-align: left;">
                        &copy; {{year}}
                        Immobl
                    </div>
                </div>
                <div class="col">
                    <!--<ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                    <a class="nav-link" target="_blank" rel="noopener">
                        About
                        Us
                    </a>
                </li>
                    </ul>-->
                </div>
            </div>
        </div>
    </footer>
</template>
<script>export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};</script>
<style>

</style>
